// ALL MAX TO MIN

// ALL MIN TO MAX
@media (max-width: $desktop) {
    .columns.no-margin-columns {
        margin-left: 15px;
        margin-right: 15px;
    }
    .column.is-full-image {
        padding-left: 0;
        padding-right: 0;
    }
    main {
        section.is-mobile-reverse {
            display: flex;
            flex-direction: column-reverse;
        }
    }
    figure {
        &.is-circle-shadow {
            img {
                height: 300px;
                width: 300px;
            }
        }
    }
}

@media (max-width: $tablet) {
    .is-h1-title,
    .is-h2-title {
        font-size: 32px;
    }
    div.social-icons {
        &.social-icons-mobile {
            a {
                color: #fff;
                font-size: 12px;
                border: 1px solid #585d65;
                padding: 15px;
                display: inline-flex;
                border-radius: 50%;
                text-decoration: none;
                background: #373b40;
                transition: transform 150ms linear;
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
    }
}
