.grid-box {
    display: grid;
}
.flex-box {
    display: flex;
}
.block-box {
    display: block;
}

.grid-box-centered {
    place-items: center;
}
