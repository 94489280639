main.not-found {
    margin-bottom: 0;
    h1,
    h3,
    p {
        color: $soft_gray !important;
    }
    // .hero-404 {
    //     background-color: #fff;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     background-position: center;
    // }
    // .is-404-gif {
    //     background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    //     min-height: 400px;
    //     background-position: center;
    //     background-size: cover;
    // }
    // .info-404 {
    //     width: 100%;
    // }

    .not-found-container {
        position: relative;
        height: 100%;
    }

    .not-found-title {
        height: 65%;
    }

    .not-found-content {
        height: 35%;
    }

    .four_zero_four_bg {
        background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
        width: 100%;
        margin: 0 auto;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        @media (min-width: 400px) {
            width: 50%;
        }
    }
}
