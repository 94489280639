.section-divider {
    display: block;
    position: relative;
    margin-bottom: 3rem;
    height: 10px;
    &:before {
        content: "";
        position: absolute;
        width: 90%;
        height: 2px;
        background: $soft_gray;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
