main.home-page {
    section.section {
        padding: 0;
        div.home-split {
            margin: 0;
            div.column:first-child {
                padding: 0;
            }
        }
    }
    figure.is-half-height img {
        height: 50vh;
        object-fit: cover;
    }
    div.home-content-container {
        position: relative;
        div.home-content {
            position: absolute;
            left: 0;
            top: -45px;
            width: 100%;
            & > div:not(:last-child) {
                margin-bottom: 30px;
            }
            div.name-and-title {
                text-align: center;
                position: relative;
                z-index: 0;

                &::before {
                    content: "";
                    position: absolute;
                    top: 15%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: 11rem;
                    width: 18rem;
                    border-radius: 150px 150px 0 0;
                    background-color: $bg_color;
                    z-index: -1;
                }
                h2 {
                    font-size: 14px;
                    font-family: "Montserrat";
                    font-weight: $semiBold;
                    text-transform: uppercase;
                }
            }
            div.welcome-message {
                display: none;
            }
            div.home-contact {
                position: relative;
                z-index: 0;
                &::before {
                    content: "";
                    background: $green_text;
                    position: absolute;
                    width: calc(100% + 0.75rem);
                    height: 1px;
                    left: -0.75rem;
                    top: 50%;
                    z-index: -1;
                }
            }
            div.home-footer {
                margin: 0;
                div.home-copy {
                    position: relative;
                    z-index: 0;
                    span {
                        background-color: $bg_color;
                        padding: 0 10px;
                    }
                }
            }
        }
    }
}

@media (min-width: $tablet) {
    main.home-page {
        min-height: 100vh;
        // section.section {
        //     div.columns {
        //         div.column:first-child {
        //             position: relative;
        //         }
        //     }
        // }
        div.home-content-container {
            div.home-content {
                display: flex;
                top: 0;
                flex-direction: column;
                position: relative;
                height: 100%;
                justify-content: center;
                padding: 0 clamp(80px, 10vw, 100px);
                div.name-and-title {
                    text-align: left;

                    &::before {
                        display: none;
                    }
                    h1 {
                        margin-bottom: 45px;
                    }
                }
                div.welcome-message {
                    display: block;
                    margin-bottom: 45px;
                    max-width: 90%;
                }
                div.home-contact {
                    &::before {
                        width: 40%;
                        left: -7rem;
                    }
                    &.has-text-centered {
                        text-align: left !important;
                    }
                }
            }
        }
        figure.is-half-height img {
            height: 100vh;
            object-fit: cover;
        }
    }
}

@media (min-width: $tablet) {
    div.home-footer {
        position: absolute;
        bottom: 0px;
        right: 0;
        width: 90%;
        &:before {
            content: "";
            position: absolute;
            width: 95%;
            height: 1px;
            background: $soft_gray;
            right: 0;
            top: 50%;
            z-index: 0;
        }
    }
}

@media (min-width: $tablet) and (max-width: 1145px) {
    main.home-page {
        div.home-content-container {
            div.home-content {
                padding: 0px clamp(60px, 5vw, 100px);
                div.name-and-title {
                    text-align: left;

                    h1 {
                        margin-bottom: 40px;
                        font-size: 50px;
                    }
                    h2 {
                        font-size: 18px;
                    }
                }
                div.welcome-message {
                    max-width: 85%;
                }
                div.home-contact {
                    &::before {
                        left: -4.5rem;
                    }
                }
                div.home-footer {
                    &:before {
                        display: none;
                    }
                    flex-direction: column;
                    bottom: 0;
                    & > .column {
                        width: 100%;
                    }
                    div.social-icons {
                        margin-bottom: 30px;
                        &:before {
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 1px;
                            background: $soft_gray;
                            right: 0;
                            top: 50%;
                            z-index: -1;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet) {
    div.home-content-container {
        div.home-content {
            div.name-and-title {
                margin-bottom: 60px;
            }

            div.home-footer {
                text-align: center;
                div.social-icons {
                    margin-bottom: 30px;
                    &:before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        background: $soft_gray;
                        right: 0;
                        top: 50%;
                        z-index: -1;
                    }
                }
            }
        }
    }
}
