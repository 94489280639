nav.navbar {
    max-height: 80px;
    height: 80px;
    transition: all 150ms ease-in-out;
    .navbar-menu {
        display: block;
        opacity: 0;
        pointer-events: none;
        transition: all 500ms ease-in-out;
        transform: translateX(100%);
    }
    .navbar-menu.is-active {
        display: block;
        opacity: 1;
        pointer-events: auto;
        transform: translateX(0px);
    }
    .navbar-item,
    .navbar-link {
        font-family: "Montserrat";
        font-weight: $extraBold;
        text-transform: uppercase;
        font-size: 18px;
        &:hover {
            color: $accent_color;
        }
    }

    .navbar-link:not(.is-arrowless)::after {
        border-color: $accent_color;
    }

    .navbar-item {
        img {
            max-height: 2.75rem;
        }
    }
    .navbar-brand {
        height: 100%;
        align-items: center;
        .navbar-burger {
            color: #fff;
            span {
                height: 3px;
                left: calc(50% - 10px);
                width: 20px;
                &:nth-child(1) {
                    top: calc(50% - 8px);
                }
                &:nth-child(3) {
                    top: calc(50% + 6px);
                }
            }
        }
    }
    &.fixed-bg {
        background: $bg_color;
        max-height: 60px;
        height: 60px;
    }
}

@media (min-width: $desktop) {
    nav.navbar {
        .navbar-item,
        .navbar-link {
            color: #fff;
        }
        .navbar-dropdown {
            .navbar-item,
            .navbar-link {
                color: #000;
            }
            a.navbar-item {
                padding-right: 1rem;
            }
        }

        .navbar-menu {
            display: flex;
            opacity: 1;
            pointer-events: auto;
            transform: translateY(0px);
        }
    }
}

@media (max-width: $desktop) {
    nav.navbar {
        max-height: 60px;
        height: 60px;
        & > .container {
            height: 60px;
        }
    }
}
