.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: #0000008f;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;

    @media (max-width: 400px) {
        align-items: flex-end;
    }
}

.modal-container {
    width: clamp(50%, 700px, 90%);
    height: auto;
    margin: auto;
    padding: 4rem 2rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $bg_color;
    border-radius: 5px;
    div.close-modal {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 24px;
        line-height: 24px;
        cursor: pointer;
        &:hover {
            color: $green_text;
        }
    }

    @media (max-width: 400px) {
        width: 100%;
        min-height: 60%;
        margin: 0;
        border-radius: 25px 25px 0px 0px;
    }
}
