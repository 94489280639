div.circle-progress-container {
    svg.CircularProgressbar {
        path.CircularProgressbar-trail {
            stroke-width: 6;
        }
    }
    div.circle-progress-number {
        display: flex;
        font-family: "Montserrat";
        @if variable-exists(extraBold) {
            font-weight: $extraBold;
        }
    }

    div.circle-progress-text {
        text-align: center;
        margin-top: 10px;
    }

    @media (max-width: 400px) {
        h3 {
            font-size: 10px;
        }
    }
}
