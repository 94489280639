body {
    font-family: "Montserrat";
    color: #fff;
    font-weight: $regular;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat";
    font-weight: $bold;
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
    color: #fff;
}

p {
    font-size: 14px;
    line-height: 26px;
}

ul {
    font-size: 14px;
}

// GENERAL TITLE CLASSES
.is-h1-title,
.is-h2-title {
    font-size: 60px;
    line-height: 1.5;
    font-weight: $bold;
}
.is-h2-subtitle,
.is-h3-subtitle {
    font-size: 14px;
}

.is-default-title-container {
    margin-bottom: 45px;
}

// GENERAL COLUMNS
section.columns {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
}

// GENERAL CASEES
main {
    flex-grow: 1;
    position: relative;
    &:not(.home-page) {
        margin: 5rem 0 20px;
        & > section:not(:last-child) {
            margin-bottom: 3rem;
        }
    }
}

div.main {
    background-color: $bg_color;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.is-bg-none {
    background-color: transparent;
}

.padding-bottom-0 {
    padding-bottom: 0;
}

.title {
    color: #fff;
}

.green-text {
    color: $green_text;
}

div.is-min-half-vh {
    min-height: 50vh;
}

section.is-hero-parent-height {
    position: absolute;
    width: 100%;
    height: 100%;
    .hero-body {
        align-items: center;
        display: flex;
    }
}

.is-hero-white {
    background: #fff;
}

// GENERAL FORM
.general-input {
    border-color: $soft_gray;
}
.general-form {
    width: 100%;
    div.field {
        &:not(:last-child) {
            margin-bottom: 1.75rem;
        }
        position: relative;
        label.label {
            position: absolute;
            z-index: 1;
            margin: 0;
            top: -0.8rem;
            left: 1rem;
            background: $bg_color;
            padding: 0 5px;
            color: #fff;
            letter-spacing: 0.1em;
        }
        input.input,
        textarea.textarea {
            background-color: $bg_color;
            color: $soft_gray;
            // border-color: $soft_gray;
            color: #fff;
            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $soft_gray;
                opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $soft_gray;
            }

            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $soft_gray;
            }
        }
    }
    .is-button-wrap {
        flex-flow: wrap;
        gap: 1em;
    }
}

.form-loading {
    width: 200px;
    display: flex;
    align-items: center;
}
.form-message-alert {
    text-align: center;
    padding: 15px;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: bolder;
    letter-spacing: 1px;
    border-radius: 5px;
}
.form-success {
    background: $green_text;
}

.form-error {
    background: #f14668;
}

// SOCIAL ICONS
div.social-icons {
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 0;

    a {
        color: #fff;
        font-size: 14px;
        border: 1px solid $soft_gray;
        padding: 25px;
        display: inline-flex;
        border-radius: 50%;
        text-decoration: none;
        background: $bg_color;
        transition: transform 150ms linear;
        &:not(:last-child) {
            margin-right: 20px;
        }
        &:hover {
            background: $green_text;
            color: #fff;
            transform: translateY(-5px);
        }
    }
}

// GENERAL IMAGE
figure {
    &.is-circle-shadow {
        img {
            height: 500px;
            width: 500px;
            margin: auto;
            object-fit: cover;
            border-radius: 50%;
            -webkit-box-shadow: 0px 0px 30px 5px $soft_gray;
            box-shadow: 0px 0px 30px 5px $soft_gray;
        }
    }
    &.position-right {
        img {
            object-position: right;
        }
    }
    &.position-left {
        img {
            object-position: left;
        }
    }
}

.image-position-top img {
    object-position: top;
}
.image-position-left img {
    object-position: left;
}
.image-position-right img {
    object-position: right;
}
.image-position-bottom img {
    object-position: bottom;
}
