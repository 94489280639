.green-button {
    color: #fff;
    text-transform: uppercase;
    font-family: "Montserrat";
    font-weight: $bold;
    border: 1px solid $green_text;
    padding: 25px 40px;
    display: inline-flex;
    text-align: center;
    border-radius: 50px;
    background: $green_text;
    font-size: 12px;
    cursor: pointer;
    align-items: center;
    svg {
        margin-left: 20px;
    }
    &:hover {
        color: #fff;
        background: $green_text;
    }
}

.green-button-transparent {
    color: #fff;
    text-transform: uppercase;
    font-family: "Montserrat";
    font-weight: $bold;
    border: 1px solid $green_text;
    padding: 25px 40px;
    display: inline-flex;
    text-align: center;
    border-radius: 50px;
    background: $bg_color;
    font-size: 12px;
    cursor: pointer;
    align-items: center;
    svg {
        margin-left: 20px;
    }
    &:hover {
        color: #fff;
        background: $green_text;
    }
}

.green-button-form {
    color: #fff;
    text-transform: uppercase;
    font-family: "Montserrat";
    font-weight: $bold;
    background: $green_text;
    border: none;
    padding: 25px;
    border-radius: 50px;
    letter-spacing: 0.1em;
    min-width: 200px;
    font-size: 16px;
    &:hover {
        color: #fff;
    }
    &:focus {
        color: #fff;
    }
}

.green-button-form-cancel {
    text-transform: uppercase;
    font-family: "Montserrat";
    font-weight: $bold;
    background: #eff1fa;
    border: none;
    padding: 25px;
    border-radius: 50px;
    min-width: 200px;
    font-size: 16px;
}

.button-container-margin-top {
    margin-top: calc(1.5rem - 0.25rem);
}

.button-container-center-on-mobile {
    @media (max-width: 400px) {
        text-align: center;
    }
}
