@font-face {
    font-family: "Poppins-Regular";
    src: url("../../fonts/poppins/Poppins-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../../fonts/poppins/Poppins-ExtraBold.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../../fonts/poppins/Poppins-SemiBold.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
    font-family: "Montserrat";
    src: url("../../fonts/montserrat/Montserrat-VariableFont_wght.ttf")
        format("truetype-variations");
    font-weight: 1 999;
}
