footer {
    padding: 20px 0 20px;
    div.footer-content {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            width: 30%;
            height: 1px;
            background: $soft_gray;
            right: 20px;
            top: 50%;
            z-index: 0;
        }
        div.footer-logo {
            img {
                max-width: 45px;
                max-height: 45px;
                margin: 0 auto;
            }
        }

        div.footer-copy {
            position: relative;
            z-index: 0;
            font-size: 12px;
        }
        div.social-icons {
            text-align: right;
        }
        @media (min-width: 400px) {
            &:before {
                width: calc(100% - 210px);
            }
        }
    }
}
